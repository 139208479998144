import * as bootstrap from 'bootstrap';
import Swiper from 'swiper/bundle';

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  "someKey": "someValue"
});

let alertNode = document.querySelector('.alert-block');
let alert = bootstrap.Alert.getInstance(alertNode);

$(".alert-block").on("close.bs.alert", function() {
  $(this).remove();
});


$(".navbar-toggler").on("keydown", function(e) {
  e.preventDefault();
  if(e.which == 32){
    console.log($('#menuCollapse'));
    if ( $('#menuCollapse').hasClass("collapse") ) {
       $('#menuCollapse').toggleClass("show");

    }

  }
  // $(this).hide();
});


if(location.hash != null && location.hash != ""){
  console.log(location.hash);
  var colel = $(location.hash + ".collapse");
  var bsCollapse = new bootstrap.Collapse(colel, {
    show: true
  });
}

/*
$(location.hash).on("shown.bs.collapse", function(e) {
	//window.location = location.hash;
	$("html,body").animate({
		scrollTop: $(location.hash).parent(".card").offset().top - 50
	});
});


$(".accordion .collapse").on("shown.bs.collapse", function(e) {
  	$("html,body").animate({
		scrollTop: $(this).parent(".card").offset().top - 50
	});
});
*/
/*
let accordionCollapseNode = document.querySelector('.accordion .collapse')
//let accordionCollapse = bootstrap.Collapse.getInstance(accordionCollapseNode);

accordionCollapseNode.addEventListener('shown.bs.collapse', function(e) {
  	$("html,body").animate({
		scrollTop: $(this).parent(".card").offset().top - 50
	});
});
*/

let accordionCollapseNodes = document.querySelectorAll('.accordion .collapse');

accordionCollapseNodes.forEach(i => {
  i.addEventListener('shown.bs.collapse', event => {
    $("html,body").animate({
      scrollTop: $(i.parentNode).offset().top - 50
    });
  });
});



// DOM Ready
$(function() {
  // Initialize

  const tooltipTriggerList = $(".entry-content a[target='_blank']:not([rel='download'])");
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, { title:'This link opens in a new window' }));

});

$(".backtotop").on("click", function(e) {
  e.preventDefault();
  $("html,body").animate({
    scrollTop: 0
  }, 300);
});


const swiper = new Swiper('.swiper', {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 0,
  loop: false,
  observer: true,
  updateOnWindowResize: true,
  a11y: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    // renderBullet: function (index, className) {
    //   return '<span class="' + className + '">' + (index + 1) + "</span>";
    // },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


